import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import {
  HubModule,
  HubModuleCTA,
  PropsWithTestId,
} from '../../../../../../types';
import { Heading, Paragraph, ModuleHeader } from '../../components';
import { HubModuleWrapper } from '../../HubModuleWrapper';
import { ExpandIcon } from './ExpandIcon';
import { AccordionHeading } from './AccordionHeading';
import { HubModuleTypes } from '../../../../../../constants';
import { CallToAction } from '../../../CallToActionGroup';
import styles from './AccordionModule.module.scss';

export interface AccordionModuleType extends HubModule {
  '@type': HubModuleTypes.Accordion;
  title: string;
  subtitle: string;
  link: HubModuleCTA;
  firstAccordionBlockOpen: boolean;
  subsections: Array<{
    title?: string;
    body?: string;
    link?: HubModuleCTA;
  }>;
}

export interface AccordionModuleProps {
  module: AccordionModuleType;
}

export default function AccordionModule({
  module: {
    '@type': type,
    title,
    subtitle,
    link,
    visualStyle,
    firstAccordionBlockOpen,
    subsections,
    elementId,
  },
  testId,
}: PropsWithTestId<AccordionModuleProps>) {
  return (
    <HubModuleWrapper
      type={type}
      theme={visualStyle}
      elementId={elementId}
      testId={testId}
    >
      <div className="container">
        <div className="row middle-md">
          <div className="col-xs-12">
            <ModuleHeader title={title} text={subtitle} lineAfterParagraph />
            <Accordion
              preExpanded={[firstAccordionBlockOpen ? 0 : '']}
              allowZeroExpanded
            >
              {subsections.map(
                ({ title: itemTitle, body, link: itemLink }, index) => (
                  <AccordionItem
                    key={index}
                    uuid={index}
                    className={styles.accordionItem}
                  >
                    <AccordionItemHeading>
                      <AccordionHeading>
                        {itemTitle && (
                          <Heading
                            testId={`accordion-item-heading-${index}`}
                            className={styles.accordionHeading}
                          >
                            {itemTitle}
                          </Heading>
                        )}
                        <AccordionItemState>
                          {({ expanded }) => <ExpandIcon isActive={expanded} />}
                        </AccordionItemState>
                      </AccordionHeading>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      {body && (
                        <Paragraph
                          testId={`accordion-item-paragraph-${index}`}
                          text={body}
                          className={styles.accordionParagraph}
                        />
                      )}
                      {itemLink && itemLink.type && (
                        <CallToAction
                          data-testid={`accordion-item-link-${index}`}
                          className={styles.ctaButton}
                          link={itemLink}
                          variant="contained"
                        />
                      )}
                    </AccordionItemPanel>
                  </AccordionItem>
                ),
              )}
            </Accordion>
            {link?.type && (
              <CallToAction
                link={link}
                variant="contained"
                align="center"
                className={styles.ctaButton}
              />
            )}
          </div>
        </div>
      </div>
    </HubModuleWrapper>
  );
}

AccordionModule.defaultProps = {
  testId: 'hub-accordion-module',
};
