import React, { HTMLAttributes, PropsWithChildren } from 'react';
import cn from 'classnames';
import { AccordionItemButton } from 'react-accessible-accordion';
import { PropsWithTestId } from '../../../../../../../types';
import styles from './AccordionHeading.module.scss';

export function AccordionHeading({
  className,
  testId,
  children,
  ...restProps
}: PropsWithTestId<PropsWithChildren<HTMLAttributes<HTMLElement>>>) {
  const headingClassName = cn(styles.wrapper, className);

  return (
    <AccordionItemButton
      {...restProps}
      data-testid={testId}
      className={headingClassName}
    >
      {children}
    </AccordionItemButton>
  );
}

AccordionHeading.defaultProps = {
  testId: 'accordion-heading',
};
