import React, { HTMLAttributes } from 'react';
import cn from 'classnames';
import { PropsWithTestId } from '../../../../../../../types';
import styles from './ExpandIcon.module.scss';

export interface ExpandIconProps extends HTMLAttributes<HTMLElement> {
  isActive: boolean | undefined;
  openIcon: string;
  closeIcon: string;
}

export function ExpandIcon({
  className,
  testId,
  isActive,
  openIcon,
  closeIcon,
  ...restProps
}: PropsWithTestId<ExpandIconProps>) {
  const iconClassName = cn('material-icons', styles.icon, className);

  return (
    <i {...restProps} className={iconClassName} data-testid={testId}>
      {isActive ? openIcon : closeIcon}
    </i>
  );
}

ExpandIcon.defaultProps = {
  testId: 'accordion-expand-icon',
  openIcon: 'expand_less',
  closeIcon: 'expand_more',
};
